/* CSS that overrides global styles in node_modules */

.ol-attribution {
}

.ol-zoom {
    top: 1.7em !important;
    left: auto !important;
    right: 0.5em !important;
}

.ol-popup {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);

    /*
    -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    */
    padding: 0;
    border-radius: 0;
    border: 1px solid #cccccc;
    bottom: 12px;
    /* left: -50px; */
    z-index: 10;
}

.ol-popup:hover {
    background-color: white;
}

/* cool pulse for overlays */
.pulse {
    position: absolute;
    bottom: -20px;
    margin-left: -5px;
    border: 20px;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
    animation: pulse 2s infinite;
}

.pulse:hover {
    animation: none;
}

.rc-slider-handle {
    border-radius: 4px;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    display: block !important;
    animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    display: block !important;
    animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
    animation-name: rcSliderTooltipZoomDownIn;
    animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
    animation-name: rcSliderTooltipZoomDownOut;
    animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
    transform: scale(0, 0);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.rc-slider-tooltip-zoom-down-leave {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@keyframes rcSliderTooltipZoomDownIn {
    0% {
        opacity: 0;
        transform-origin: 50% 100%;
        transform: scale(0, 0);
    }
    100% {
        transform-origin: 50% 100%;
        transform: scale(1, 1);
    }
}

@keyframes rcSliderTooltipZoomDownOut {
    0% {
        transform-origin: 50% 100%;
        transform: scale(1, 1);
    }
    100% {
        opacity: 0;
        transform-origin: 50% 100%;
        transform: scale(0, 0);
    }
}

.rc-slider-tooltip {
    position: absolute;
    left: -9999px;
    top: -9999px;
    visibility: visible;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip-hidden {
    display: none;
}

.rc-slider-tooltip-placement-top {
    padding: 4px 0 8px 0;
}

.rc-slider-tooltip-inner {
    padding: 6px 2px;
    min-width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 1;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background-color: #6c6c6c;
    border-radius: 6px;
    box-shadow: 0 0 4px #d9d9d9;
}

.rc-slider-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
    bottom: 4px;
    left: 50%;
    margin-left: -4px;
    border-width: 4px 4px 0;
    border-top-color: #6c6c6c;
}

@media print {
    .highcharts-reset-zoom {
        display: none;
    }
}
